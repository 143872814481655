.manage-permissions-adit-section {
  position: absolute;
  top: 116px;
  left: 26%;
}

.manage-permissions-adit-input {
  padding: 13px 16px;
  box-sizing: border-box;
  width: 336px;
  height: 48px;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
}

.manage-form-peradit {
  height: 877px;
  padding: 48px 27px 29px 48px;
}

.manage-form-peradit > p {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  margin-bottom: 18px;
}

.manage-permissions-adit-form > p {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #262c31;
  margin-bottom: 18px;
}

.manage-permissions-adit {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.manage-permissions-adit-title {
  width: 152px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #adb4ba;
}

.manage-permissions-adit-radio,
.manage-permissions-adit-checkbox {
  display: flex;
  align-items: flex-start;
}

.manage-permissions-adit-radio input,
.manage-permissions-adit-checkbox input {
  appearance: none;
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
  border: 1.5px solid #ccd1d7;
  border-radius: 50%;
  margin: 0 6px 0 0;
}

.manage-permissions-adit-radio input:checked {
  border: 5px solid #36a6e6;
}

.manage-permissions-adit-checkbox input:checked {
  background: url(/public/img/manager-edit-check.svg) center center no-repeat;
  border: none;
}

.manage-permissions-adit-radio label,
.manage-permissions-adit-checkbox label {
  margin-right: 19px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #262c31;
}

.manage-permissions-adit-radio div span,
.manage-permissions-adit-checkbox div span {
  display: block;
  margin-top: 13px;
  margin-bottom: 41px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #a4856a;
}

.manage-permissions-adit-access-right div > span {
  margin-top: 22px;
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #262c31;
}

.manage-permissions-adit-access-right div:first-of-type > span {
  margin-top: 0px;
}

.access-right-container div form {
  margin-bottom: 22px;
}

.manage-form-save-peradit button {
  bottom: 30px;
}

.manage-permissions-adit-input:disabled {
  background: #eef2f3;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
}

.service-manage-form {
  width: 659px;
  margin-bottom: 2px !important;
}

.service-manage-form label {
  display: inline-block;
  margin-bottom: 20px;
}

.service-manage-form label:nth-of-type(7),
.service-manage-form label:nth-of-type(8) {
  margin-bottom: 0px;
}

.service-manage-form label:nth-of-type(6) {
  margin: 0;
}
