.gathering-inquiry-seemore-container {
  position: absolute;
  top: -73px;
  left: 0%;
  bottom: 0%;
  background-color: #00000050;
  width: 100%;
  min-height: 100%;
  height: auto;
}

.gathering-inquiry-seemore-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 785px;
  min-height: 670px;
  height: auto;
  background: #ffffff;
  border: 1px solid #d7dbe0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 54px 30px 30px 40px;
}

.gathering-inquiry-seemore-popup > span {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.007em;
  color: #25282b;
  display: block;
  margin-bottom: 37px;
}

.manage-input-container-gathering-popup {
  margin-bottom: 9px;
}

.manage-input-container-gathering-popup input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.manage-input-container-popup {
  align-items: flex-start;
}

.Manage-input-textarea-popup {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
  width: 563px;
  height: 198px;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  padding: 0;
  padding-top: 13px;
  padding-left: 16px;
  resize: none;
  box-sizing: border-box;
}

.Manage-input-textarea-popup::-webkit-scrollbar {
  width: 13px;
  background-color: #ffffff00;
}

.Manage-input-textarea-popup::-webkit-scrollbar-thumb {
  width: 3px;
  background: #adb4ba;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 10px;
}

.Manage-input-textarea-popup::placeholder {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.gathering-inquiry-seemore-detail span:nth-child(2) {
  width: 479px;
}

.gathering-inquiry-seemore-detail:last-of-type {
  position: relative;
}

.answer {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.gathering-inquiry-seemore-detail:last-of-type textarea {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
  width: 705px;
  height: 194px;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  padding-top: 13px;
  padding-left: 16px;
  resize: none;
  box-sizing: border-box;
}

.gathering-inquiry-seemore-detail:last-of-type textarea::-webkit-scrollbar {
  width: 13px;
  background-color: #ffffff00;
}

.gathering-inquiry-seemore-detail:last-of-type
  textarea::-webkit-scrollbar-thumb {
  width: 3px;
  background: #adb4ba;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 10px;
}

.gathering-inquiry-seemore-detail:last-of-type textarea::placeholder {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.gathering-inquiry-seemore-detail:last-of-type a {
  position: absolute;
  bottom: 16px;
  right: 19px;
  background: #5a636a;
  border: 1px solid #5a636a;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
}

.editAnswer-btn {
  display: block;
  margin-top: 20px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px 8px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #262c31;
}

.answer-true-contanier {
  width: 479px;
}

.answer-true-contanier > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
  display: inline-block;
  box-sizing: border-box;
}
