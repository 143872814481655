.go-back-container-mileage-seemore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 924px;
  box-sizing: border-box;
}

.go-back-container-mileage-seemore div:nth-child(2) {
  display: flex;
  margin-bottom: 19px;
}

.go-back-container-mileage-seemore div:nth-child(2) a:first-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  width: 160px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #262c31;
}

.manage-form-mileage-see-more {
  padding: 48px 48px 51px 48px;
  height: auto;
}

.manage-form-mileage-see-more div {
  margin-bottom: 26px;
}

.manage-form-mileage-see-more > span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  display: block;
  margin-bottom: 29px;
}

.Manage-form-view-details img {
  display: inline-block;
}

.Manage-form-view-details-lastchild {
  margin-bottom: 0;
}
