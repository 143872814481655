.Manage-permissions-catagory {
  width: 1090px;
  height: 41px;
  border-bottom: 1px solid #f2f4f5;
}

.Manage-permissions-catagory a {
  display: inline-block;
  width: 139px;
  height: 41px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.003em;
  color: #808991;
}

.Manage-permissions-catagory a:active {
  border-bottom: 2px solid #3ac8f5;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.003em;
  color: #3ac8f5;
}

.Manage-permissions-catagory + span {
  margin-top: 55px;
}

.Manage-permissions-type a {
  display: inline-block;
  padding: 6px 13px;
  background: #a3a7aa;
  border-radius: 23px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  margin-right: 9px;
}

.Manage-permissions-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.Manage-permissions-search-number span {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #adb4ba;
}

.Manage-permissions-search-number span:nth-child(2) {
  margin-left: 7px;
  color: #262c31;
}

.Manage-permissions-search {
  display: flex;
}

.Manage-permissions-search div:first-child {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 244px;
  height: 36px;
  background: #f2f4f5;
  border-radius: 18px;
}

.Manage-permissions-search div:first-child span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #b8bfc4;
}

.Manage-permissions-search div:first-child img {
  width: 16px;
  height: 16px;
}

.Manage-permissions-search div:nth-child(2) {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99px;
  height: 36px;
  padding: 7px 16px 6px 14px;
  background: #262c31;
  border-radius: 18px;
  margin-left: 16px;
}

.Manage-permissions-search div:nth-child(2) img {
  width: 12px;
  height: 12px;
}

.Manage-permissions-search div:nth-child(2) span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
}

.manage-table-permissions {
  margin-top: 18px;
  box-sizing: border-box;
}

.manage-table-permissions table thead tr,
.manage-table-permissions table tbody tr {
  box-sizing: border-box;
}

.manage-table-permissions table thead tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #778088;
}

.manage-table-permissions table tbody tr td {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #636c73;
}

.manage-table-permissions table thead tr th:first-child,
.manage-table-permissions table tbody tr td:first-child {
  /* box-sizing: border-box; */
  padding-left: 18px;
  text-align: left;
  width: 119px;
}

.manage-table-permissions table tbody tr td:first-child span {
  display: block;
  width: 119px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.manage-table-permissions table thead tr th:nth-child(2),
.manage-table-permissions table tbody tr td:nth-child(2) {
  text-align: left;
  width: 135px;
}

.manage-table-permissions table thead tr th:nth-child(3),
.manage-table-permissions table tbody tr td:nth-child(3) {
  text-align: left;
  width: 117px;
}

.manage-table-permissions table thead tr th:nth-child(4),
.manage-table-permissions table tbody tr td:nth-child(4) {
  text-align: center;
  width: 87px;
  padding-right: 16px;
}

.manage-table-permissions table tbody tr td:nth-child(4) a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.manage-table-permissions table tbody tr td:nth-child(4) span {
  display: block;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #262c31;
}

.manage-table-permissions table thead tr th:nth-child(5),
.manage-table-permissions table tbody tr td:nth-child(5) {
  text-align: center;
  width: 107px;
  padding-right: 40px;
}

.manage-table-permissions table tbody tr td:nth-child(5) a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.manage-table-permissions table tbody tr td:nth-child(5) span {
  display: block;
  margin-right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #262c31;
}

.manage-table-permissions table thead tr th:nth-child(6),
.manage-table-permissions table tbody tr td:nth-child(6) {
  text-align: left;
  width: 262px;
}

.manage-table-permissions table thead tr th:nth-child(7),
.manage-table-permissions table tbody tr td:nth-child(7) {
  text-align: center;
  width: 41px;
  padding-right: 8px;
}

.manage-table-permissions table tbody tr td:nth-child(7) a {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 29px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #ef594b;
  background: #ffffff;
  border: 1px solid #ef594b;
  border-radius: 4px;
}

.manage-table-permissions table thead tr th:last-child,
.manage-table-permissions table tbody tr td:last-child {
  padding-right: 29px;
  text-align: left;
  width: 65px;
}

.manage-table-permissions table tbody tr td:last-child a {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 29px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #808991;
  background: #ffffff;
  border: 1px solid #dfe1e4;
  border-radius: 4px;
}

.width-approval {
  width: 65px !important;
}

.manage-table-permissions table tbody tr td:last-child img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

.table-sub-menu-permissions {
  width: 117px;
}

.table-sub-menu-right {
  left: -13%;
  width: 130px;
}
