.manage-main-container {
  width: 1043px;
}

.manage-main-container > span:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #808991;
}

.main-greetings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-greetings > h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -0.007em;
  color: #262c31;
}

.main-user-count-container {
  display: flex;
  align-items: center;
  width: 491px;
  height: 56px;
  padding: 14px 35.42px 14px 38.58px;
  box-sizing: border-box;
  background: #f2f4f5;
  border-radius: 6px;
}

.main-user-count-container span {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.003em;
  color: #636c73;
}

.all-user-count-container {
  margin-right: 53px;
  width: 165px;
}

.all-user-count-container span:first-child::after,
.main-registration-secession span:first-child::after {
  content: url("/public/img/dash-col.svg");
  margin: 0px 14px;
}

.main-registration-secession {
  width: 199px;
}

.main-visitant-graph-container > span:first-child,
.main-quick-menu > span:first-child {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #262c31;
}

.main-quick-menu-list {
  width: 1043px;
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-quick-menu-container {
  width: 328px;
  height: 106px;
  box-sizing: border-box;
  border: 1px solid #d7dbe0;
  border-radius: 8px;
  padding: 27px 32px;
  margin-bottom: 17px;
  cursor: pointer;
}

.main-quick-menu-container > span {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #004098;
}

.main-quick-menu-container div {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.main-quick-menu-container div span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  margin-right: 6px;
}

#main-circle-1 {
  position: absolute;
  top: 2%;
  left: 8%;
}

#main-circle-2 {
  position: absolute;
  bottom: 5%;
  right: 0%;
}

/*방문자 그래프*/
.main-graph-container {
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  box-sizing: border-box;
  width: 1044px;
  height: 298px;
  margin-top: 12px;
  margin-bottom: 29px;
}

.main-graph-container {
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.graph-chevron-left,
.graph-chevron-right {
  width: 28px;
}

.graph-chevron-left > img,
.graph-chevron-right > img {
  cursor: pointer;
}

.main-visitant-graph {
  width: 1044px;
  height: 298px;
  position: relative;
}

.main-visitant-graph-container > span:first-child,
.main-quick-menu > span:first-child {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #262c31;
}

.graph-axis-wrap {
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  gap: 42px;
}

.graph-axis-wrap span {
  font-weight: 500;
  font-size: 13px;
  line-height: 18.75px;
  text-align: right;
  color: #b8bfc4;
  margin-right: 18px;
  display: block;
  width: 14px;
  height: 19px;
}

.graph-axis-wrap > div {
  display: flex;
  align-items: center;
}

.graph-axis-line {
  width: 846px;
  border-bottom: 0.5px solid #d1d5d950;
}

.graph-bar-wrap {
  position: absolute;
  bottom: 8%;
  right: 7%;
  /* transform: translate(-50%, -53%); */
  height: 180px;
  width: 846px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.graph-bar-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 70px;
  height: 180px;
}

.graph-line-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graph-bar {
  display: flex;
  width: 22px;
  background: var(--key-blue, #0080ff);
  border-radius: 50px 50px 0px 0px;
}

.graph-value-count {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24.5px;
  text-align: center;
  color: var(--gray-6, #636c73);
  display: block;
  width: 70px;
  height: 25px;
}
.graph-value-date {
  margin-top: 18px;
  display: block;
  width: 70px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20.25px; /* 144.643% */
  color: #636c73;
}
