.manage-form-message-adit {
  position: relative;
  padding: 48px 60px 39px 48px;
  min-height: 835px;
  height: auto;
}

.manage-form-message-adit > span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  display: block;
  margin-bottom: 24px;
}

.Manage-input-container-general-member-message {
  margin-bottom: 24px;
}

.Manage-input-container-general-member-message:nth-of-type(4) {
  margin-bottom: 15px;
}

.Manage-input-container-general-member-message input,
.general-member-adit-dirth > div {
  width: 366px;
}

.Manage-input-container-general-member-message span {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.003em;
  color: #adb4ba;
  width: 152px;
}

.Manage-input-container-general-member-message textarea {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
  width: 664px;
  height: 197px;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  box-sizing: border-box;
  padding-top: 13px;
  padding-left: 16px;
  resize: none;
}

.Manage-input-container-general-member-message textarea::-webkit-scrollbar {
  width: 13px;
  background-color: #ffffff00;
}

.Manage-input-container-general-member-message
  textarea::-webkit-scrollbar-thumb {
  width: 3px;
  background: #adb4ba;
  background-clip: padding-box;
  border: 5px solid transparent;
  border-radius: 10px;
}

.Manage-input-container-general-member-message textarea::placeholder {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #adb4ba;
}

.recipient-list {
  width: 664px;
  min-height: 129px;
  height: auto;
  border: 1px solid #d7dbe0;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  box-sizing: border-box;
}

.recipient-detail {
  width: 190px;
  height: 29px;
  border: 1px solid #eef2f3;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 6.5px 11px;
  margin-right: 8px;
}

.recipient-detail div span {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #262c31;
  margin-right: 5px;
}
