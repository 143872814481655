.go-back-container-spacesupport-seemore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 924px;
  box-sizing: border-box;
}

.go-back-container-spacesupport-seemore div:nth-child(2) {
  display: flex;
  margin-bottom: 19px;
}

.go-back-container-spacesupport-seemore div:nth-child(2) a:first-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  width: 65px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #ef594b;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #ef594b;
}

.manage-form-spacesupport-see-more {
  padding: 47px 48px 50px 48px;
  min-height: 823px;
  height: auto;
}

.manage-form-spacesupport-see-more > div {
  margin-bottom: 28px;
}

.manage-form-spacesupport-see-more > span {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.003em;
  color: #262c31;
  display: block;
  margin-bottom: 29px;
}

.Manage-form-view-details-lastchild {
  margin-bottom: 0;
}

.seemore-consent-checkbox:first-of-type {
  margin-bottom: 18px;
}

.seemore-consent-checkbox {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.seemore-consent-checkbox span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
}

.seemore-consent-checkbox input {
  width: 20px;
  height: 20px;
  border: 1.5px solid #636c73;
  border-radius: 4px;
  appearance: none;
}

.seemore-consent-checkbox input:checked {
  border: 1.5px solid #36a6e6;
  background-color: #36a6e6;
  background-image: url("/public/img/check.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.manage-form-spacesupport-see-more .Manage-form-view-details span:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.003em;
  color: #5a636a;
}
